var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "full-width pt-6" },
    [
      _c("h3", { staticClass: "mb-6" }, [_vm._v("Enter your private key")]),
      _c("mew-input", {
        staticClass: "PrivateKeyInput",
        attrs: {
          label: "Private Key",
          placeholder: "Enter your Private Key",
          rules: _vm.privKeyRulles,
          type: "password",
        },
        model: {
          value: _vm.privateKey,
          callback: function ($$v) {
            _vm.privateKey = $$v
          },
          expression: "privateKey",
        },
      }),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c(
            "v-row",
            {
              staticClass: "align-center justify-center pt-4",
              attrs: { dense: "" },
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4" } },
                [
                  _c("mew-button", {
                    staticClass: "PrivateKeyAccess",
                    attrs: {
                      "has-full-width": "",
                      title: "Access Wallet",
                      "btn-size": "xlarge",
                      disabled: !_vm.disableBtn,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.unlockBtn.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }